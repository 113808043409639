:root {
  --color-one: #fe5c2b;
  --color-two: #ff8fe9;
  --color-three: #584cff;
  --color-four: #fff031;
  --color-dark:#333131;
  --background: #ff000000;
  --base-size: 14px;
  --base-padding: 32px;
}

body {
  font-size:var(--base-size);
  background: var(--background);
}

.container {
  border: solid #f8f9fa;
    background-color: white;
    padding: 1.5rem;
    padding-top:0;
    border-top:none;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.color-nav {
    background-color: var(--color-four);
}

.App-header {
  background-color: var(--color-three);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal .show {
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wishlist .jumbotron {

  min-height: 300px;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
  border-radius: 0 0 .3rem .3rem;
}

.jumbotron {
  border-bottom: solid #f8f9fa;
  background-color:#3acb2e;
  transition: background-color 2s linear;
    -webkit-transition: background-color 2000ms linear;
    -moz-transition: background-color 2000ms linear;
    -o-transition: background-color 2000ms linear;
    -ms-transition: background-color 2000ms linear;
}

.placeholder.wishlist img {
  background-color: white;
  height: 150px;
  border-radius: 50%;
  width: 150px;
  padding: 0.5rem;
}

.swing {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float:left;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}
.swing img {
    border: 5px solid #f8f8f8;
    display: block;
}
.swing:after{
    content: '';
    position: absolute;  
    width: 20px; height: 20px;  
    border: 1px solid #999;
    top: -10px; left: 50%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
}
/* nail */
.swing:before{
    content: '';
    position: absolute;
    width: 5px; height: 5px;
    top: -14px;left: 54%;
    z-index: 5;
    border-radius: 50% 50%;
    background: #000;
}
 
@keyframes swing {
    0% { transform: rotate(3deg); }
    100% { transform: rotate(-3deg); }
}

.jumbotron img {
  float:right;
}

.jumbotron.white {
background:white;
}

.jumbotron.default {
background-image: url('./balloons.svg');
background-repeat: repeat-x;
padding-top: 80px;
background-color:#3acb2e;
}

.jumbotron.pink {
background-color: var(--color-two);
color: var(--color-dark);
}

.jumbotron.blue {
  background-color: var(--color-three);
  color: var(--color-two);

}

.jumbotron.yellow {
  background-color: var(--color-four);
  color: var(--color-dark);
}

.jumbotron.balloons {
background-image: url('./balloons.svg');
background-repeat: repeat-x;
padding-top: 60px;
background-color:white;
}

.jumbotron.seal {
  background-image: url('./seal.svg');
  background-repeat: repeat-x;
  padding-top: 60px;
  background-color:#1587d3a3;
  }

.table.wishlist th:last-of-type, .table.wishlist td:last-of-type {
  text-align:right; 
}

.suggestions {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.suggestions li {
  border: 1px solid #ddd; /* Add a border to all links */
  margin-top: -1px; /* Prevent double borders */
  background-color: #f6f6f6; /* Grey background color */
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove default text underline */
  font-size: 18px; /* Increase the font-size */
  color: black; /* Add a black text color */
  display: block; /* Make it into a block element to fill the whole list */
}
.suggestions li div.suggestion_img,
.suggestions li div.suggestion_information {
  display: table-cell;
}

.suggestions li .price {
  padding:0 5px 0 5px;
}



/* First: default */
.card {
  flex:1 1;
  margin-left: 1em;
  margin-right:1em;
  align-items: center;
  justify-content: center;
  background: var(--color-three);
  text-align: center;
  padding: 1em;
  margin-bottom: 3%;
  border-radius: calc(2 * var(--base-size));
  color: var(--color-two);
  transform: rotate(-2deg);
  transition: 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  min-width:300px;
}

.card .row {
    align-items: center;
  justify-content: center;
}


.card:hover {
  transform: rotate(2deg) scale(1.1);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}


/* Second */
.card:nth-child(3n-1) {
  /*pink*/
  background-color: var(--color-two);
  transform: rotate(2deg);
}


.card:nth-child(3n-1):hover {
  transform: rotate(-2deg) scale(1.1);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

/* Third */
.card:nth-child(3n) {
  /*yellow*/
  background-color: var(--color-four);
  transform: rotate(-1deg);
}



.card:nth-child(3n):hover {
  transform: rotate(1deg) scale(1.1);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}


.card-title {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.025rem;
  padding: 0.25em 0 0.5em 0;
  margin: 0;
  color: var(--color-four);
}

.card:nth-child(3n) .card-title {
  color: var(--color-one);
}

.card-text,.card-text a  {
  color: var(--color-two);
}

.card:nth-child(3n) .card-text,
.card:nth-child(3n) .card-text a,
.card:nth-child(3n-1) .card-text,
.card:nth-child(3n-1) .card-text a {
  color: var(--color-dark);
}
